package receipt

abstract class Flag(val bitValue: Int)

sealed class OrderStatuses(val orderStatus: Int): Flag(orderStatus) {
    data object Open: OrderStatuses(1)
    data object Canceled: OrderStatuses(2)
    data object Completed: OrderStatuses(4)
    data object Voided: OrderStatuses(8)
}

sealed class PaymentStatusTypes(val paymentStatusType: Int): Flag(paymentStatusType) {
    data object Canceled: PaymentStatusTypes(1)
    data object CardPresented: PaymentStatusTypes(2)
    data object PaymentRequested: PaymentStatusTypes(4)
    data object PresentedEnrollment: PaymentStatusTypes(8)
    data object WaitingEnrollmentDecision: PaymentStatusTypes(16)
    data object AcceptedEnrollment: PaymentStatusTypes(32)
    data object DeclinedEnrollment: PaymentStatusTypes(64)
    data object AlreadyMember: PaymentStatusTypes(128)
    data object PresentedSelectOffer: PaymentStatusTypes(256)
    data object CompletedSelectOffer: PaymentStatusTypes(512)
    data object PresentedRecap: PaymentStatusTypes(1024)
    data object RequestedAuthorization: PaymentStatusTypes(2048)
    data object CompletedAuthorization: PaymentStatusTypes(4096)
    data object Complete: PaymentStatusTypes(8192)
    data object ApplyFailedToPOS: PaymentStatusTypes(16384)
    data object TokenInPaymentDecision: PaymentStatusTypes(32768)
    data object PaymentAdjustmentRequested: PaymentStatusTypes(65536)
    data object ApplyingDiscount: PaymentStatusTypes(131072)
    data object Batched: PaymentStatusTypes(262144)
    data object VoidPending: PaymentStatusTypes(524288)
    data object CancelPending: PaymentStatusTypes(1048576)
    data object Voided: PaymentStatusTypes(2097152)
    data object OpenTab: PaymentStatusTypes(4194304)
}

sealed class PaymentTypes(val paymentTypeId: Int): Flag(paymentTypeId) {
    data object Credit: PaymentTypes(1)
    data object Cash: PaymentTypes(2)
    data object Chip: PaymentTypes(4)
    data object Swipe: PaymentTypes(8)
    data object Contactless: PaymentTypes(16)
    data object ChargeAccount: PaymentTypes(32)
    data object Debit: PaymentTypes(64)
}

sealed class QuestionTypes(val questionType: Int): Flag(questionType) {
    data object Mandatory: QuestionTypes(1)
    data object Important: QuestionTypes(2)
    data object FiveStar: QuestionTypes(4)
    data object Thumb: QuestionTypes(8)
}

sealed class OptInTypes(val optInType: Int): Flag(optInType) {
    data object OfferSMS: OptInTypes(1)
    data object OfferEmail: OptInTypes(2)
    data object ReceiptSMS: OptInTypes(4)
    data object ReceiptEmail: OptInTypes(8)
    data object TermsAndConditionsNotAccepted: OptInTypes(16)
    data object OptedOut: OptInTypes(32)
    data object WeeklySMS: OptInTypes(64)
    data object WeeklyEmail: OptInTypes(128)
}

fun Int?.hasFlag(flag: Flag): Boolean {
    return this?.let { ((this and flag.bitValue) == flag.bitValue) } ?: false
}

fun Int?.hasFlags(vararg flags: Flag): Boolean {
    val hasAllFlags = flags.firstOrNull { flag -> !this.hasFlag(flag) } == null
    return hasAllFlags
}